import http from '@/utils/request'

export function getdictTypeList(type:any){
    return http({
        url:`/sys/dict/data/selected/${type}`,
        method:'get', 
        
    })
}   

// 文章
export function article(num:Number,size:Number,data:any){
    return http({
        url:`/article/list?pageNum=${num}&pageSize=${size}`, 
         method: 'post',
         "data":data
     })
}
 