<script setup lang="ts"></script>

<template>
  <footer class="footer">
    <div class="server">
      <ul class="container">
        <li>
          <img src="@/assets/images/zjbz.svg" alt="" />
          <div>
            <h5>资金保障</h5>
            <p>网店交易期间 全程托管</p>
            <p>资金保障 保障网店转让双方权益</p>
          </div>
        </li>
        <li>
          <img src="@/assets/images/flyz.svg " alt="" />
          <div>
            <h5>法律援助</h5>
            <p>网店交易签署权威合同</p>
            <p>提供专业法律援助</p>
          </div>
        </li>
        <li>
          <img src="@/assets/images/jypt.svg" alt="" />
          <div>
            <h5>交易陪同</h5>
            <p>交易额满足条件</p>
            <p>派专人全程陪同交易</p>
          </div>
        </li>
        <li>
          <img src="@/assets/images/ppbz.svg" alt="" />
          <div>
            <h5>品牌保障</h5>
            <p>人工筛查挂售店铺</p>
            <p>确保网店转让信息真实可靠</p>
          </div>
        </li>
      </ul>
    </div>

    <div class="foot">
      <div class="container">
        <div class="my clearboth">
          <div class="left">
            <dl>
              <dt>商城入驻</dt>
              <dd>京东电商</dd>
              <dd>天猫商城</dd>
              <dd>多多平台</dd>
            </dl>
            <dl>
              <dt>优选服务</dt>
              <dd>合同保障</dd>
              <dd>保障交易</dd>
              <dd>专属客服</dd>
            </dl>
            <dl>
              <dt>帮助中心</dt>
              <dd>业务咨询</dd>
              <dd>投诉建议</dd>
              <dd>售后服务</dd>
            </dl>
            <dl>
              <dt>关于我们</dt>
              <dd>公司简介</dd>
              <dd>商务合作</dd>
              <dd>新闻公告</dd>
            </dl>
          </div>
          <div class="right">
            <div class="left">
              <p class="phone-title">商务合作</p>
              <p class="phone">028-64062263</p>

              <p class="email-title">投诉建议</p>
              <p class="email">jiancha@guanjingmao.com</p>
            </div>
            <div class="right">
              <img style="margin-left: 20px" src="@/assets/images/home/qrcode.jpg" alt="" class="qrcode" />
            </div>
          </div>
        </div>

        <div class="link">
          友情链接：
          <a href="https://www.riskbird.com/">风鸟</a>
          <a href="https://www.gsxt.gov.cn/">国家企业信息公示系统</a>
          <a href="https://www.tmkoo.com/">标库网</a>
          <a href="https://sbj.cnipa.gov.cn/sbj/index.html">商标局</a>
          <a href="http://www.800000361.com/gs/">一般纳税人查询</a>
        </div>

        <div class="photo">
          <img src="@/assets/images/aq4.png" alt="" />
          <img src="@/assets/images/aq2.png" alt="" />
          <img src="@/assets/images/alipay.png" alt="" />
          <!-- <img src="@/assets/images/home.png" alt="" /> -->
          <img src="@/assets/images/weixin 1.png" alt="" />
          <img src="@/assets/images/aq1.png" alt="" />
        </div>

        <div class="footer">
      <p>
        Copyright © 2022-2024 冠京猫·四川冠京猫网络科技有限公司 版权所有  
      <el-link href="https://beian.mps.gov.cn/#/query/webSearch" target="_blank">蜀ICP备2022022060号</el-link>  
      <el-link href="https://www.beianx.cn/info/414894363525189" target="_blank">川公网安备51010502014466号</el-link>  
      </p>
    </div>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
.server {
  min-width: 1341px;
  height: 85px;
  margin-top: 50px;
  padding: 20px 0px;
  background-color: #fff;

  li {
    float: left;
    width: 25%;
    justify-content: center;
    align-items: center;
    display: flex;


    img {
      float: left;
      width: 77px;
      height: 77px;
    }

    div {
      float: left;
      margin-left: 15px;


      h5 {
        color: #007fff;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      p {
        font-size: 13px;
        color: #999999;

      }
    }
  }
}

.foot {
  min-width: 1341px;
  padding-top: 70px;
  height: 100%;
  color: #fff;
  padding-bottom: 30px;
  background-color: #17224d;

  dl {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 140px;

    .left {
      margin-top: -10px;

    }

    dt {
      font-size: 24px;
      margin-bottom: 25px;

    }

    dd {
      margin-bottom: 20px;
      color: #b6b7be;
      margin-left: 55px;
    }
  }

  .phone {
    font-size: 20px;
    margin-bottom: 30px;
  }

  .email-title {
    color: #b6b7be;
  }

  .qrcode {
    width: 150px;
    height: 150px;
    margin-top: 15px;
  }
}

.link {
  margin-top: 50px;
  font-size: 18px;

  a {
    color: #fff;
    text-decoration: none;
    margin-right: 7px;
  }
}

.photo {
  img {
    margin: 50px;
  }
}

.footer {
  width: 100%;
  padding: 50px 0;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  p {
    width: 800px;
    text-align: center;
    line-height: 26.06px;
    color: rgba(95, 102, 136, 1);
    font-size: 16px;
  }
  .el-link{
    color: rgb(95, 102, 136);
    font-size: 16px;
  }
}

.right {
  .left {
    margin-top: 40px;
  }
}
</style>
