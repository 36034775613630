<script setup lang="ts">
    import { defineProps, defineEmits, reactive } from 'vue';
    import { ElMessage } from 'element-plus'
    const props = defineProps({
        dialogis: {
            type: Boolean,
            default: false,
        },

    })
    const form = reactive({
        phone:''
    })

    // 需要定义defineProps
    const emit = defineEmits(["confirm", 'cancel'])

    const confirm = () => {
        if (!form.phone) {
            ElMessage({
                message: '请输入手机号',
                type: 'error',
            })
            return
        }
        emit("confirm", form.phone) 
        form.phone = ''
    }

    const cancel = () => {
        emit("cancel")
        form.phone = ''
    }

    const handleClose = ()=>{
        emit("cancel")
    }

</script>
<template>
    <!-- 快速入驻 -->
    <div class="">
        <el-dialog :model-value="props.dialogis" :show-close="false" title="验证客服" width="500" :before-close="handleClose">
            <el-form :model="form" label-width="auto" style="max-width: 600px">
                <el-form-item label="电话">
                    <el-input v-model="form.phone" placeholder="请输入" />
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="cancel">取消</el-button>
                    <el-button type="primary" @click="confirm">
                        验证
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>
<style lang="scss">

</style>