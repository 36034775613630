<script setup lang="ts">
import { ref } from 'vue';
import TopContainer from '@/components/TopContainer.vue';
import NavBar from '@/components/NavBar.vue';
import Tail from '@/components/Tail.vue';
import { messageAdd } from '@/api/index';
import BuyStore from '@/components/buyStore.vue';
import { ElMessage } from 'element-plus';
import { useRouter, useRoute } from 'vue-router';
const router = useRouter();
const route = useRoute();
// 买，卖弹窗
const BuyStoreDialog = ref(false);
const title = ref('');
const buyStore = e => {
  BuyStoreDialog.value = true;
  title.value = e;
};
const handleClose = () => {
  BuyStoreDialog.value = false;
};
// sousuo
const searchValue = ref('');
const brns = () => {
  router.push({ path: '/list', query: { searchdata: searchValue.value } });
};
const searchBtns = r => {
  searchValue.value = r;
  router.push({ path: '/list', query: { searchdata: r } });
};
const Confirm = (e, r) => {
  if (r == '我要买店') {
    messageAdd({ ...e, type: 'buyStore' }).then(res => {
      if (res.code == 200) {
        ElMessage({
          message: res.message,
          type: 'success'
        });
      }
    });
  } else {
    messageAdd({ ...e, type: 'sellStore' }).then(res => {
      if (res.code == 200) {
        ElMessage({
          message: res.message,
          type: 'success'
        });
      }
    });
  }
  BuyStoreDialog.value = false;
};
</script>

<template>
  <!-- 弹窗组件-->
  <BuyStore :buyStoredialog="BuyStoreDialog" :title="title" @handleClose="handleClose" @Confirm="Confirm" />
  <header class="head">
    <TopContainer />

    <div
      class="search"
      v-if="
        route.path !== '/my' &&
        route.path !== '/my/mybuy' &&
        route.path !== '/my/RechargeAccount' &&
        route.path !== '/my/allOrders' &&
        route.path !== '/my/sellOnlineShop' &&
        route.path !== '/my/Cashwithdrawal' &&
        route.path !== '/my/FundDetails' &&
        route.path !== '/my/BindBankCard' &&
        route.path !== '/my/userInfo' &&
        route.path !== '/my/authentication' &&
        route.path !== '/my/changePhoneNumber' &&
        route.path !== '/my/securitySettings'
      "
    >
      <div class="search-content container">
        <div class="logo-container">
          <router-link to="/">
            <img src="@/assets/images/home/logo.png" alt="Logo" class="logo" />
          </router-link>
        </div>

        <div class="search-box">
          <div class="search">
            <input class="search_input" type="text" placeholder="请输入网店关键词 、网店类目、入驻类型、入驻平台" v-model="searchValue" minlength="1" maxlength="30" />
            <button class="search_button" @click="brns()">搜索</button>
          </div>
        </div>

        <div class="right-section">
          <div class="search-bar1" @click="buyStore('我要买店')">
            <img src="@/assets/images/home/buy.png" alt="ico1" />
            <span id="buy">我要买店</span>
          </div>
          <div class="search-bar2" @click="buyStore('我要出售')">
            <img src="@/assets/images/home/sell.png" alt="ico2" />
            <span id="sell">我要出售</span>
          </div>
        </div>

        <div class="hot-search">
          <span class="rm">热门搜索：</span>
          <span @click="searchBtns('京东慧采')" style="color: #007fff">京东慧采</span>
          <span @click="searchBtns('京东自营')">京东自营</span>
          <span @click="searchBtns('天猫国际')">天猫国际</span>
          <span @click="searchBtns('商标授权')">商标授权</span>
        </div>
      </div>
    </div>
  </header>
  <NavBar
    v-if="
      route.path !== '/my' &&
      route.path !== '/my/mybuy' &&
      route.path !== '/my/allOrders' &&
      route.path !== '/my/RechargeAccount' &&
      route.path !== '/my/sellOnlineShop' &&
      route.path !== '/my/Cashwithdrawal' &&
      route.path !== '/my/FundDetails' &&
      route.path !== '/my/BindBankCard' &&
      route.path !== '/my/userInfo' &&
      route.path !== '/my/authentication' &&
      route.path !== '/my/changePhoneNumber' &&
      route.path !== '/my/securitySettings'
    "
  />
  <router-view></router-view>
  <Tail />
</template>

<style lang="scss" scoped>
.head {
  min-width: 100%;

  .search {
    height: 135px;
    background: #fff;

    .search-content {
      position: relative;

      .logo-container {
        height: 110px;
        display: flex;
        align-items: center;
        float: left;

        .logo-container img {
          margin-top: 32px;
          margin-bottom: 52px;
        }
      }

      .search-box {
        float: left;
        margin-top: 2em;
        margin-left: 6em;

        .search {
          height: 42px;
          border: 2px solid #007fff;
          position: relative;
          z-index: 2;

          .search_input {
            line-height: 42px;
            border-radius: 20px;
            width: 520px;
            text-indent: 15px;
            font-size: 14px;
            color: #505050;
            padding-right: 25px;
            border: none;
            outline: none;
          }

          .search_button {
            background-color: #0000ff;
            outline: none;
            margin-right: -100px;
            border: 0px solid #007fff;
            color: white;
            width: 100px;
            height: 46px;
            margin-top: -2px;
            cursor: pointer;
            float: right;
          }

          .search_button:hover {
            background-color: #0000ff;
          }
        }
      }

      .right-section {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 29px;
        margin-right: 0;

        #buy,
        #sell {
          position: relative;
          top: -18px;
          cursor: pointer;
        }

        .search-bar1,
        .search-bar2 {
          font-size: 13px;
          display: inline-block;
          align-items: center;
          background-color: white;
          color: #505050;

          img {
            width: 50px;
            height: 50px;
          }
        }

        .search-bar1 {
          margin-right: 15px;
        }
      }

      .hot-search {
        .rm {
          font-weight: bold;
        }
        cursor: pointer;
        display: inline-block;
        color: #333;
        padding: 5px 10px;
        margin-right: 10px;
        margin-left: -555px;
        margin-top: 75px;
        font-size: 12px;

        span {
          margin-right: 10px;
          margin-left: -3px;
        }
      }
    }
  }
}
</style>
