<template>
  <!-- <TopContainer /> -->
  <div class="header">
    <div class="header-box container">
      <div class="logo">
        <div class="logo-container">
          <img src="@/assets/images/home/logo.png" alt="Logo" class="logo" />
        </div>
      </div>

      <!-- 菜单栏 -->
      <div class="navigation-bar">
        <div class="top-bar-center">
          <ul class="menu-right">
            <li>
              <router-link to="/" :class="{ active: route.path === '/' }"
                >首页</router-link
              >
            </li>
            <li>
              <router-link
                to="/jdrz"
                :class="{ active: route.path === '/jdrz' }"
                >京东入驻</router-link
              >
            </li>
            <li>
              <router-link
                to="/tmrz"
                :class="{ active: route.path === '/tmrz' }"
                >天猫入驻</router-link
              >
            </li>
            <li>
              <router-link
                to="/dyrz"
                :class="{ active: route.path === '/dyrz' }"
                >抖店入驻</router-link
              >
            </li>
            <li><router-link to="/list">网店转让</router-link></li>

            <li>
              <router-link
                to="/gszh"
                :class="{ active: route.path === '/gszh' }"
                >工商知产</router-link
              >
            </li>
            <li>
              <router-link
                to="/wdzr"
                :class="{ active: route.path === '/wdzr' }"
                >网店估价</router-link
              >
            </li>
            <li>
              <router-link
                to="/wdxq"
                :class="{ active: route.path === '/wdxq' }"
                >冠京猫研究所</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- 内容区 -->
  <div class="position container">所在位置：首页 > 个人中心</div>
  <div class="main-box">
    <!-- 内页面最大容器 -->

    <div class="left">
      <el-col>
        <el-menu
          class="el-menu-vertical-demo"
          router
          @open="handleOpen"
          @close="handleClose"
        >
          <el-sub-menu index="/my">
            <template #title>
              <el-icon>
                <ShoppingCartFull />
              </el-icon>
              <span>我是买家</span>
            </template>
            <el-menu-item index="/my/mybuy">我要求购</el-menu-item>
            <el-menu-item index="/my/allOrders">所有订单</el-menu-item>
          </el-sub-menu>
          <el-sub-menu index="/my">
            <template #title>
              <el-icon>
                <SoldOut />
              </el-icon>
              <span>我是卖家</span>
            </template>
            <el-menu-item index="/my/sellOnlineShop">出售网店</el-menu-item>
          </el-sub-menu>
          <el-sub-menu index="/my">
            <template #title>
              <el-icon>
                <CreditCard />
              </el-icon>
              <span>资金管理</span>
            </template>
            <el-menu-item index="/my/RechargeAccount">我要充值</el-menu-item>
            <el-menu-item index="/my/Cashwithdrawal">我要提现</el-menu-item>
            <el-menu-item index="/my/FundDetails">资金明细</el-menu-item>
            <el-menu-item index="/my/BindBankCard">绑定银行卡</el-menu-item>
          </el-sub-menu>
          <el-sub-menu index="/my">
            <template #title>
              <el-icon>
                <Setting />
              </el-icon>
              <span>个人设置</span>
            </template>
            <el-menu-item index="/my/userInfo">个人信息</el-menu-item>
            <el-menu-item index="/my/authentication">实名认证</el-menu-item>
            <el-menu-item index="/my/changePhoneNumber"
              >修改手机号</el-menu-item
            >
            <el-menu-item index="/my/securitySettings">安全设置</el-menu-item>
          </el-sub-menu>
        </el-menu>
      </el-col>
    </div>

    <div class="rightbox"><router-view /></div>
  </div>
  <div style="margin-top: 50px" class="container">
    <!-- 心意店铺 -->
    <HeartStroe />
  </div>
</template>

<script setup lang="ts">
import HeartStroe from "@/components/heartStroe.vue";
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
const router = useRouter();
const route = useRoute();
const handleOpen = (key: string, keyPath: string[]) => {
  console.log(key, keyPath);
  router.push(key);
};
const handleClose = (key: string, keyPath: string[]) => {
  console.log(key, keyPath);
};
</script>

<style lang="scss" scoped>
.header {
  /* width: 100vw; */
  height: 80px;
  background-color: #fff;
}

.header-box {
  height: 80px;

  margin: 0 auto;
}

.logo-container {
  height: 80px;
  display: flex;
  align-items: center;
  float: left;

  .logo-container img {
    margin-top: 32px;
    margin-bottom: 52px;
  }
}

.navigation-bar {
  display: flex;
  align-items: center;
}

.top-bar-center {
  width: 800px;
  height: 50px;
}

.menu-right {
  float: right;
  display: flex;
  margin-top: 30px;

  li {
    margin-left: 30px;
  }
}

.main-box {
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  width: 1341px;
  margin: 0 auto;
}

.mbx {
  color: #767676;
  font-size: 15px;
  margin: 5px 0;
}

.left {
  width: 210px;
  min-height: 100vh;
  background-color: #fff;
}

.rightbox {
  width: calc(100% - 225px);
  min-height: 100vh;
  position: relative;
}
</style>
