<script setup lang="ts">
import { useRoute } from "vue-router";
import { watch, ref } from "vue";
import { uesIsOpen } from "@/store/isOpen";
import DropdownMenu from "./DropDownMenu.vue";
const route = useRoute();
const isopen = uesIsOpen();
// 定义是否可以点击
const isClick = ref(false);
watch(
  () => route.path,
  (newValue) => {
    // 此处写监听路由后要执行的事情
    if (newValue == "/") {
      isopen.openType = 1;
      isClick.value = false;
    } else {
      isopen.openType = 2;
      isClick.value = true;
    }
  },
  { immediate: true }
);

const open = () => {
  if (isClick.value == false) {
    return;
  } else {
    if (isopen.openType == 2) {
      isopen.openType = 1;
    } else if (isopen.openType == 1) {
      isopen.openType = 2;
      return;
    }
  }
};
</script>

<template>
  <div class="navigation-bar">
    <div class="top-bar-center">
      <div class="menu-left">
        <div class="menu-left-top" @click="open">
          <img src="@/assets/images/home/menu_ico/menu.png" alt="menu_ico" />
          <span>商品全部分类</span>
        </div>
        <div class="dropdown-menu" v-if="isopen.openType == 1">
          <DropdownMenu />
        </div>
      </div>

      <ul class="menu-right">
        <li>
          <router-link to="/" :class="{ active: route.path === '/' }">首页</router-link>
        </li>
        <li>
          <router-link to="/jdrz" :class="{ active: route.path === '/jdrz' }">京东入驻</router-link>
        </li>
        <li>
          <router-link to="/tmrz" :class="{ active: route.path === '/tmrz' }">天猫入驻</router-link>
        </li>
        <li>
          <router-link to="/dyrz" :class="{ active: route.path === '/dyrz' }">抖店入驻</router-link>
        </li>
        <li><router-link to="/list">网店转让</router-link></li>

        <li>
          <router-link to="/gszh" :class="{ active: route.path === '/gszh' }">工商知产</router-link>
        </li>
        <li>
          <router-link to="/" :class="{ active: route.path === '/wdzr' }">网店估价</router-link>
        </li>
        <li>
          <router-link to="/" :class="{ active: route.path === '/wdzr' }">网站工具</router-link>
        </li>
        <li>
          <router-link to="/" style="border-right: none"
            :class="{ active: route.path === '/wdxq' }">冠京猫研究所</router-link>
        </li>
      </ul>
    </div>
    </div>
</template>

<style lang="scss" scoped>
.dropdown-menu {}

.menu-right li a:last-child {
  border-right: none;
}

.navigation-bar {
  height: 45px;
  background: #fff;
  position: relative;

  .top-bar-center {
    margin: 0 auto;
    width: 1341px;
    position: relative;
    overflow: hidden;

    .menu-left {
      z-index: 1000 !important;
      background: linear-gradient(to right, #0000ff, #0072ff);
      position: relative;
      width: 210px;
      height: 45px;
      top: 0px;

      .menu-left-top {
        cursor: pointer;
        margin-left: 30px;
        width: 210px;
        height: 45px;
        position: relative;

        img {
          width: 28px;
          height: 28px;
          margin-right: 15px;
          margin-top: 10px;
        }

        span {
          font-size: 16px;
          font-weight: bold;
          line-height: 45px;
          color: white;
          position: absolute;
          left: 35px;
        }
      }
    }

    .menu-right {
      margin-top: -45px;
      margin-left: 230px;
      height: 45px;
      display: flex;
      align-items: center;

      li {
        height: 45px;
        font-size: 18px;
        top: 0;
        left: 220px;
        font-weight: bold;

        a {
          display: inline-block;
          padding: 0px 27px;
          color: #333333;
          font-size: 16px;
          border-right: 1px solid #cbcbcb;
          line-height: 18px;
          margin-top: 10px;
          text-decoration: none;

          &:hover {
            color: #007fff;
          }

          &.active {
            color: #007fff;
          }
        }
      }
    }
  }
}
</style>
