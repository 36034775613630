<script setup lang="ts">
import { ref, onMounted } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { useRouter } from "vue-router";
import QuickEntry from "./quickEntry.vue";
import Complaint from "./complaint.vue";
import isCustomer from "./isCustomer.vue";
import { isCustomerService, messageAdd } from "@/api/index.ts";
import { getdictTypeList } from '@/api/public.ts'
const storePlatforms = ref()
onMounted(() => {
  getdictTypeList('store_platform').then(res => {
    if (res.code == 200) {
      storePlatforms.value = res.data
    }
  })
})


const router = useRouter();
const getTimeState = () => {
  let timeNow = new Date();
  let hours = timeNow.getHours();
  let state = ``;
  if (hours >= 0 && hours <= 10) {
    state = `早上好`;
  } else if (hours > 10 && hours <= 14) {
    state = `中午好`;
  } else if (hours > 14 && hours <= 18) {
    state = `下午好`;
  } else if (hours > 18 && hours <= 24) {
    state = `晚上好`;
  }
  return state;
};

// 广告位初始打开
const showAd = ref(true);
const closeAd = () => {
  showAd.value = false; // 隐藏广告
};

// 个人中心
const token = localStorage.getItem("token");
const phone = localStorage.getItem("phone");
const goMy = () => {
  if (token) {
    router.push("/my");
  } else {
    open();
  }
};
// 提示弹窗
const open = () => {
  ElMessageBox.confirm("您需要登录！", "提示", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      router.push("/login");
    })
    .catch(() => {
      ElMessage({
        type: "info",
        message: "取消登录",
      });
    });
};
// 快速入驻
const quickEntry = () => {
  FdialokEntry.value = true;
};

const FdialokEntry = ref(false);
const EntryConfirm = (e) => { // 确认快速入驻逻辑，提交
  messageAdd({ type: "quickEntry", platform: e.platform, content: e.content, phone: e.phone }).then(
    (res) => {
      if (res.code == 200) {
        ElMessage({
          message: res.message,
          type: "success",
        });
      }
    }
  );
  FdialokEntry.value = false;
};
const cancel = () => {
  FdialokEntry.value = false;
};
// 投诉
const ComplaintDialog = ref(false);
// 投诉按钮
const complaint = () => {
  ComplaintDialog.value = true;
};
const ComplaintConfirm = (e) => {
  messageAdd({ type: "complaint", content: e.notes, phone: e.phone }).then(
    (res) => {
      if (res.code == 200) {
        ElMessage({
          message: res.message,
          type: "success",
        });
      }
    }
  );
  ComplaintDialog.value = false;
};
const Complaintcancel = () => {
  ComplaintDialog.value = false;
};

//验证客服
const dialogis = ref(false);
const isCustomerSereve = () => {
  dialogis.value = true;
};
const CustomerConfirm = (e) => {
  isCustomerService({ phone: e }).then((res) => {
    if (res.data) {
      ElMessage({
        type: "success",
        message: res.message,
      });
    } else {
      ElMessage({
        type: "error",
        message: res.message,
      });
    }
  });
  dialogis.value = false;
};
const Customercancel = () => {
  dialogis.value = false;
};
// 退出登录
const logout = () => {
  ElMessageBox.confirm("确定要退出登录吗?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      localStorage.removeItem("token");
      router.replace("/login");
    })
    .catch(() => {
      // catch error
    });
};
</script>

<template>
  <!-- 广告弹窗 -->
  <!-- <li class="flex-active-slide" v-if="showAd">
    <div class="guanggao">
      <img src="@/assets/images/home/guanggao.png" alt="广告图" draggable="false" />
      <div class="close-btn" @click.stop="closeAd">X</div>
    </div>

  </li> -->
  <div class="top-content">
    <div class="container">
      <ul class="login">
        <li>{{ getTimeState() }}，欢迎来到冠京猫一站式企业服务平台！</li>
        <li class="cursor" v-if="!token">
          <img src="@/assets/images/home/login.png" alt="" />
          <router-link to="/login" class="login-hd"> 登录</router-link> &nbsp;
        </li>
        <li class="cursor" v-if="!token">
          <img src="@/assets/images/home/register.png" alt="" />
          <router-link to="/register" class="login-hd"> 注册</router-link>
          &nbsp;
        </li>
        <li class="cursor" v-if="token">
          <span style="margin-left: 15px">您好!&nbsp;{{ phone }}</span><span style="margin-left: 10px; color: #007fff"
            @click="logout">退出</span>
        </li>
      </ul>

      <div class="right">
        <nav class="top-navigation">
          <!-- <router-link style="color: #007fff"
            >真假客服验证</router-link
          > -->
          <a @click="isCustomerSereve" style="color: #007fff">真假客服验证</a>
          <a @click="quickEntry">快速入驻</a>
          <a @click="goMy">个人中心</a>
          <a @click="complaint">投诉建议</a>
          <a class="follow-us" style="color: #505050; cursor: pointer">
            关注我们
            <div class="qrcode-box">
              <img src="@/assets/images/home/qrcode.jpg" alt="qrcode" />
              <p>冠京猫公众号</p>
            </div>
          </a>

          <div id="hotline" class="right">
            24小时服务热线：<span>
              <img src="@/assets/images/home/call.png" alt="call" style="margin-right: 2px" />
              19108048127
            </span>
          </div>
        </nav>
      </div>
    </div>
  </div>
  <!-- 验证 -->
  <isCustomer :dialogis="dialogis" @confirm="CustomerConfirm" @cancel="Customercancel" />
  <!-- 快速入驻 -->
  <QuickEntry :dialokEntry="FdialokEntry" :storePlatforms="storePlatforms" @confirm="EntryConfirm" @cancel="cancel" />
  <!-- 投诉建议 -->
  <Complaint :complaintdialog="ComplaintDialog" @confirm="ComplaintConfirm" @cancel="Complaintcancel" />
  <!-- 真假客服验证 -->
</template>

<style lang="scss" scoped>
.flex-active-slide {
  position: relative;
  margin: 0 auto;
  width: 100%;

  img {
    width: 100%;
  }
}

.guanggao {
  width: 1920px;
  margin: 0 auto;
  position: relative;

  .close-btn {
    position: absolute;
    top: 0px;
    right: 286px;
    cursor: pointer;
    background-color: #003ba8;
    color: white;
    padding: 5px 10px;
    font-weight: bold;
    font-size: 14px;
    line-height: 1;
    text-align: center;
    z-index: 1;
  }

}


.top-content {
  height: 32px;
  line-height: 32px;
  font-size: 12px;
  color: #505050;
  background-color: #f3f8fc;
  border-bottom: 1px solid #f3f8fc;

  .top-navigation a:hover {
    color: #007fff;
  }

  img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    margin-top: -1px;
    vertical-align: -3px;
  }

  .login {
    li {
      float: left;
    }

    .login-hd {
      color: #505050;
    }

    .login-hd:hover {
      color: #007fff;
    }
  }

  .top-navigation {
    position: relative;

    a {
      color: #505050;
      cursor: pointer;
    }

    a:not(.follow-us)::after {
      content: "丨";
      margin-left: 5px;
      margin-right: 5px;
      color: #d9dee6;
    }

    a.follow-us::after {
      content: "丨";
      margin-left: 5px;
      margin-right: 5px;
      color: #d9dee6;
    }

    a::hover {
      color: #007fff;
    }

    .qrcode-box {
      text-align: center;
      display: none;
      position: absolute;
      z-index: 99999;
      left: 249px;
      background-color: white;
      padding: 5px 5px 0px;
      border: 1px solid white;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);

      img {
        width: 100px;
        height: 100px;
      }

      p {
        margin-top: -20px;
      }
    }

    .follow-us:hover .qrcode-box {
      display: block;
    }

    /* .follow-us:hover+.qrcode-box {
       
      } */

    #hotline {
      img {
        width: 15px;
        height: 15px;
        vertical-align: -3px;
        margin: 0 5px;
      }

      span {
        font-weight: 700;
        color: #007fff;
      }
    }
  }
}
</style>
