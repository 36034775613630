<script setup lang="ts">
import { defineProps, defineEmits, reactive,  } from 'vue';

const props = defineProps({
    dialokEntry: {
        type: Boolean,
        default: false,
    },
    storePlatforms:Array
})

// 需要定义defineProps
const emit = defineEmits(["confirm", 'cancel'])

const confirm = () => {
    emit("confirm", form)
    form.platform = null
    form.phone = ''
    form.content = ''
}
const cancel = () => {
    emit("cancel")
    form.platform = null
    form.phone = ''
    form.content = ''
}
const form = reactive({
    platform: null,
    phone: "",
    content: "",
})


</script>
<template>
    <!-- 快速入驻 -->
    <div class="">
        <el-dialog :model-value="props.dialokEntry" :show-close="false" title="快速入驻" width="500">
            <el-form :model="form" label-width="auto" style="max-width: 600px">
                <el-form-item label="入驻平台">
                    <el-select v-model="form.platform" placeholder="请选择所在平台" :clearable="true" :filterable="true">
                        <el-option v-for="(child, index) in storePlatforms" :label="child.name" :value="child.code"
                            :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="联系方式">
                    <el-input v-model="form.phone" placeholder="请输入联系方式" />
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="form.content" maxlength="230" placeholder="请输入备注" show-word-limit
                        type="textarea" />
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="cancel">取消入驻</el-button>
                    <el-button type="primary" @click="confirm">
                        提交
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>
<style lang="scss"></style>