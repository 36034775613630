<script setup lang="ts">
    import { defineProps, defineEmits, reactive } from 'vue';
    import { ElMessage } from 'element-plus'
    const props = defineProps({
        complaintdialog: {
            type: Boolean,
            default: false,
        },
    })
    // 需要定义defineProps
    const emit = defineEmits(["confirm", 'cancel'])

    const confirm = () => {
        if (!form.phone) {
            ElMessage({
                message: '请输入电话',
                type: 'warning',
            })
            return 
        } else if (!form.notes) { 
            ElMessage({
                message: '请输入投诉内容',
                type: 'warning',
            })
            return
        }
        emit("confirm", form)
        form.phone = '' 
        form.notes = '' 
    }
    const cancel = () => {
        emit("cancel")
        form.phone = ''
        form.notes = ''
    }
    const form = reactive({
        phone: "",
        notes: "",
    })
    const handleClose = () => {
        emit("cancel")
    }

</script>
<template>
    <!-- 快速入驻 -->
    <div class="">
        <el-dialog :model-value="props.complaintdialog" :show-close="false" ::before-close="handleClose" title="投诉"
            width="500">
            <el-form :model="form" label-width="auto" style="max-width: 600px">

                <el-form-item label="投诉内容">
                    <el-input v-model="form.notes" maxlength="230" placeholder="请输入投诉内容" show-word-limit
                        type="textarea" />
                </el-form-item>
                <el-form-item label="联系方式">
                    <el-input v-model="form.phone" placeholder="请输入联系方式" />
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="cancel">
                        取消
                    </el-button>
                    <el-button type="primary" @click="confirm">
                        提交
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>
<style lang="scss">

</style>