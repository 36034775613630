import axios from 'axios';
import { ElMessage, ElMessageBox } from 'element-plus'
import router from '@/router'
// 创建axios实例
const http = axios.create({
  baseURL: "/api",
  timeout: 100000,
});
// 请求拦截器
http.interceptors.request.use(config => {
  // 可以在这里添加例如token等请求头
  const token = localStorage.getItem('token')
  config.headers['token'] = token;
  return config;
}, error => {
  // 请求错误处理
  return Promise.reject(error);
});

// 响应拦截器
http.interceptors.response.use(response => {
  console.log("response", response)
  // 对响应数据做处理，例如只返回data部分
  if (response.data.code == 4012 || response.data.code == 4011) {
    ElMessage({
      message: response.data.message,
      type: "error",
    });
    localStorage.removeItem('token')
    localStorage.removeItem('phone')
    router.replace({ path: '/login' })
  } else if (response.data.code == 401) {
    ElMessage({ message: response.data.message, type: 'error' })
    // return Promise.reject(new Error(response.data.message))
  } else if (response.data.code == 500) {
    localStorage.removeItem('token')
    localStorage.removeItem('phone')
    ElMessage({ message: response.data.message, type: 'error' })

    // return Promise.reject(new Error(response.data.message)) 
  }

  return response.data;
}, error => {
  // 响应错误处理     



  return Promise.reject(error);
});

export default http;