<script setup lang="ts">
import { defineProps, defineEmits, reactive, ref, onMounted } from 'vue';
import {
    dictall
} from '@/api/list'
import { messageAdd } from '@/api/index.ts'
import { ElMessage } from 'element-plus'
import { sendCode } from '@/api/sendCode'
const props = defineProps({
    buyStoredialog: {
        type: Boolean,
        default: false,
    },
    title: {
        type: String
    }
})
// 需要定义defineProps
const emit = defineEmits(["confirm", 'cancel', 'handleClose'])

const confirm = () => {
    if (form.content == '' && form.phone == '' && form.code == '') {
        ElMessage('请输入信息')
    } else {
        emit("Confirm", form, props.title)
        form.platform = null
        form.phone = ""
        form.content = ""
    }
}
const handleClose = () => {
    emit("handleClose")
    form.platform = null
    form.phone = ""
    form.content = ""
}
const cancel = () => {
    emit("cancel")
    form.platform = null
    form.phone = ""
    form.content = ""
}
const form = reactive({
    platform: null,
    phone: "",
    content: "",
})

const platforms = ref([])
onMounted(() => {
    dictall('store_platform').then(res => {
        if (res.code == 200) {
            platforms.value = res.data
        }
    })
})
</script>
<template>
    <!-- 快速入驻 -->
    <div class="">
        <el-dialog :model-value="props.buyStoredialog" :before-close="handleClose" :title="title" width="600">
            <div class="tip">请留下您的联系方式，稍后有专业客服为您解答</div>
            <el-form :model="form" label-width="auto" style="max-width: 600px">
                <el-form-item label="平台">
                    <el-select v-model="form.platform" placeholder="请选择">
                        <el-option v-for="item in platforms" :key="item.code" :label="item.name" :value="item.code" />
                    </el-select>
                </el-form-item>
                <el-form-item label="手机号">
                    <el-input v-model="form.phone" placeholder="请输入手机号" />
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="form.content" maxlength="230" placeholder="请输入备注" show-word-limit
                        type="textarea" />
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="confirm">
                        立即提交
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>
<style lang="scss">
.tip {
    margin-bottom: 30px;
    color: #0068FF;
}
</style>