import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/index.vue'
import '@/assets/css/global.css'
import My from '@/layout/my.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('@/views/home/index.vue')
      },
     
      {
        path: '/list',
        name: 'List',
        component: () => import('@/views/list/index.vue')
      },
      {
        path: '/jdrz',
        name: 'jdrz',
        component: () => import('@/views/jdrz/index.vue')
      },
      {
        path: '/gszh',
        name: 'gszh',
        component: () => import('@/views/gszh/index.vue')
      },
      {
        path: '/tmrz',
        name: 'tmrz', 
        component: () => import('@/views/tmrz/index.vue')
      },
      {
        path: '/dyrz',
        name: 'dyrz',
        component: () => import('@/views/dyrz/index.vue')
      },
      {
        path: '/wdzr/:id', 
        name: 'wdzr',
        component: () => import('@/views/wdzr/index.vue')
      },
      { 
        path: '/wdxq/:id', 
        name: 'wdxq',
        component: () => import('@/views/wdxq/index.vue') 
      },
      {  
        path: '/my',  
        name: 'My',  
        component: My,  
        children: [  
          {  
            path: '',   
            name: 'myInfo',  
            component: () => import('@/views/my/index.vue')  
          },  
          {  
            path: 'userInfo',   
            name: 'UserInfo',  
            component: () => import('@/views/my/components/UserInfo.vue')  
          },  
          {  
            path: 'securitySettings',   
            name: 'SecuritySettings',  
            component: () => import('@/views/my/components/SecuritySettings.vue')  
          },  
    
          {  
            path: 'authentication',   
            name: 'Authentication',  
            component: () => import('@/views/my/components/Authentication.vue')  
          },  
          {  
            path: 'changePhoneNumber',   
            name: 'ChangePhoneNumber',  
            component: () => import('@/views/my/components/ChangePhoneNumber.vue')  
          } ,
          {  
            path: 'rechargeAccount',   
            name: 'RechargeAccount',  
            component: () => import('@/views/my/components/RechargeAccount.vue')  
          } ,
          {  
            path: 'fundDetails',   
            name: 'FundDetails',  
            component: () => import('@/views/my/components/FundDetails.vue')  
          } ,
          {  
            path: 'bindBankCard',   
            name: 'BindBankCard',  
            component: () => import('@/views/my/components/BindBankCard.vue')  
          } ,
          {  
            path: 'Cashwithdrawal',   
            name: 'Cashwithdrawal',  
            component: () => import('@/views/my/components/Cashwithdrawal.vue')  
          } ,
          {  
            path: 'mybuy',   
            name: 'Mybuy',  
            component: () => import('@/views/my/components/mybuy.vue')  
          } ,
          {  
            path: 'allOrders',   
            name: 'AllOrders',  
            component: () => import('@/views/my/components/AllOrders.vue')  
          } ,
          {  
            path: 'withdrawMoney',   
            name: 'WithdrawMoney',  
            component: () => import('@/views/my/components/Cashwithdrawal.vue')  
          } ,
          
          {  
            path: 'sellOnlineShop',   
            name: 'SellOnlineShop',  
            component: () => import('@/views/my/components/SellOnlineShop.vue')  
          } ,
        ]  
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/register/index.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition){
    return { top: 0 };
  }
})
// 路由拦截
router.beforeEach((to, from, next) => { 
  if (to.path !== '/login' && !localStorage.getItem('token')) {
    next('/login');  
  } else {
    next();  
  }
})


export default router
