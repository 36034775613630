<template>
    <!-- 心意店铺 -->
    <div class="heart_shop">
        <p class="title">没有找到您心仪的店铺？</p>
        <p class="small_title">不要急哦，好多点没有展示出来，马上联系顾问获取！</p>

        <div class="btns">
            <div class="kefu" @click="kefu('联系客服帮您找')">联系客服帮您找</div>
            <div class="fen">或</div>
            <div class="lianxi" @click="lianxi('快速求购店铺')">发布求购需求</div>

        </div>
        <div class="heart_shopcard">
            <div class="shop-item" v-for="(item, index) in heartlistData" :key="index">
                <div class="inner">
                    <div class="inner_name">
                        <i>{{item.storeType}}</i>
                        <span class="ellipsis">{{item.title}}</span>
                    </div>
                    <div class="info" style="height: 50px;">
                        <ul class="left">
                            <li>
                                <p>店铺类型</p>
                                <p>{{item.mallType}}</p>
                            </li>
                            <li>
                                <p>商标类型</p>
                                <p>{{item.trademarkType}}</p>
                            </li>
                            <li>
                                <p>地区</p>
                                <p>{{item.address}}</p>
                            </li>
                            <li>
                                <p>主营项目</p>
                                <p>{{item.mainCategory}}</p>
                            </li>
                        </ul>

                    </div>
                    <div class="pricev">
                        <p class="jia">￥{{item.storePrice}}万</p>
                        <p class="detail">
                            <router-link :to="`/wdxq/${item.id}`"> <i>》</i>查看详情 </router-link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <StorePoup :title="Dtitle" :buyStoredialog="dialog" @confirm="confirm" @cancel="cancel" @handleClose="cancel" />
</template>

<script lang="ts" setup>
    import { ref, onMounted } from 'vue'
    import {
        heartlist
    } from '@/api/list'
    import StorePoup from '@/components/buyStore.vue'
    const heartlistData = ref([])
    //  联系
    const Dtitle = ref('')
    const dialog = ref(false)
    const kefu = (e) => {
        Dtitle.value = e
        dialog.value = true
    }
    const lianxi = (e) => {
        Dtitle.value = e
        dialog.value = true
    }
    const confirm = (e, r) => {
    if (r == '联系客服帮您找') {
      messageAdd({ ...e, type: "contactCustomer" }).then(res => {
        if (res.code == 200) {
          ElMessage({
            message: res.message,
            type: "success"
          })
        }
      })
    } else if (r == '快速求购店铺') {
      messageAdd({ ...e, type: "publishShopping" }).then(res => {
        if (res.code == 200) {
          ElMessage({
            message: res.message,
            type: "success"
          })
        }
      })
    }
    dialog.value = false
  }
  const cancel = () => {
    dialog.value = false
  }
  const handleClose = () => {
    dialogVisible.value = true
  }
    onMounted(() => {
        heartlist(1, 3, {}).then(res => {
            heartlistData.value = res.data
        })
    })
</script>

<style lang="scss" scoped>
    .shop-item {
        float: left;
        width: 400px;
        height: 220px;
        margin-right: 14px;
        margin-bottom: 14px;
        background-color: #F8FBFD;
        overflow: hidden;

        &:nth-child(3n) {
            margin-right: 0;
        }



        .inner {
            width: 350px;
            height: 120px;
            margin: 0 auto;
            margin-top: 24px;
            border-bottom: 1px dashed #999999;

            .inner_name {
                display: flex;
                align-items: center;

                i {
                    margin-right: 10px;
                    display: inline-block;
                    padding: 2px 5px;
                    height: 19px;
                    color: #fff;
                    font-size: 12px;
                    line-height: 25px;
                    text-align: center;
                    border-radius: 5px;

                    background-color: #d62119;
                }

                span {
                    margin-right: 10px;
                    font-size: 15px;
                    font-weight: 700;
                }
            }

            .info {
                ul {
                    li {
                        position: relative;
                        float: left;
                        width: 70px;
                        margin-top: 20px;

                        &::after {
                            content: '';
                            position: absolute;
                            right: 0;
                            top: 0;
                            transform: translateY(30%);
                            width: 0;
                            height: 33px;
                            border-right: 1px dashed #999999;
                        }

                        &:last-of-type::after {
                            border-right: 0;
                        }

                        p {
                            font-size: 13px;
                            text-align: center;
                        }

                        p:first-of-type {
                            color: #666666;
                            margin-bottom: 10px;
                        }
                    }
                }

                img {
                    width: 97px;
                    height: 97px;
                    border-radius: 50%;
                }

            }



        }
    }

    .pricev {
        margin-top: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;




        .jia {

            font-size: 24px;
            text-align: left;
            color: rgba(222, 114, 62, 1);
        }

        .detail {
            font-size: 16px;
            color: #007fff;
            background-color: #E6EFFD;
            border: 1px solid #007fff;

            display: flex;
            align-items: center;
            height: 27px;
            padding-right: 15px;

            a {
                display: flex;
                color: #007fff;
                align-items: center;

                i {
                    text-align: center;
                    height: 27px;
                    width: 20px;
                    display: block;
                    margin-right: 15px;
                    color: #fff;
                    background-color: #007fff;
                }
            }

        }
    }

    .heart_shop {
        margin-bottom: 60px;
        background-color: #fff;

        .btns {
            cursor: pointer;
            padding-top: 12px;
            display: flex;
            align-items: center;
            justify-content: center;

            .fen {
                margin: 0 20px;
                display: block;
                font-size: 12px;
            }

            .kefu {
                font-size: 12px;
                font-weight: 500;
                line-height: 17.38px;
                color: #fff;
                text-align: center;
                width: 146px;
                height: 37px;
                line-height: 37px;
                border-radius: 18.5px;
                background: rgba(36, 104, 242, 1);
            }

            .lianxi {
                text-align: center;
                width: 146px;
                height: 37px;
                line-height: 37px;
                border-radius: 18.5px;
                border: 2px solid #007fff;
                font-size: 12px;
                font-weight: 500;
                color: rgba(36, 104, 242, 1);
            }
        }

        .title {
            padding-top: 29px;
            font-size: 24px;
            font-weight: 500;
            line-height: 34.75px;
            text-align: center;
        }

        .small_title {
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0px;
            line-height: 17.38px;
            color: rgba(0, 0, 0, 1);
            text-align: center;
            padding-top: 12px;
        }
    }

    .heart_shopcard {
        padding: 20px;
        margin-bottom: 60px;
        display: flex;
        justify-content: space-between;
    }
</style>