<script setup lang="ts"></script>

<template>
  <div class="menu">
    <dl class="box1">
      <dt>
        <img
          src="@/assets/images/home/menu_ico/jd.png"
          alt="京东入驻"
          class="imghover"
        />
        <p>京东入驻</p>
      </dt>
      <dd>
        <router-link to="/jdrz">京东小店</router-link>
        <router-link to="/jdrz">京东自营</router-link>
        <router-link to="/jdrz">京东慧采</router-link>
        <router-link to="/jdrz">京东POP</router-link>
        <router-link to="/jdrz">京东买手</router-link>
        <router-link to="/jdrz">京东国际</router-link>
      </dd>
    </dl>

    <dl class="box1">
      <dt>
        <img
          src="@/assets/images/home/menu_ico/tm.png"
          class="imghover"
          alt="天猫入驻"
        />
        <p>天猫入驻</p>
      </dt>
      <dd>
        <router-link to="/tmrz">旗舰店</router-link>
        <router-link to="/tmrz">专营店</router-link>
        <router-link to="/tmrz">专卖店</router-link>
        <router-link to="/tmrz">天猫卖场</router-link>
        <router-link to="/tmrz">天猫超市</router-link>
        <router-link to="/tmrz">天猫国际</router-link>
      </dd>
    </dl>

    <dl class="box1">
      <dt>
        <img
          src="@/assets/images/home/menu_ico/wdzr.png"
          class="imghover"
          alt="网店转让"
        />
        <p>网店转让</p>
      </dt>
      <dd>
        <router-link to="/list">京东网店</router-link>
        <router-link to="/list">天猫网店</router-link>
        <router-link to="/list">淘宝网店</router-link>
        <router-link to="/list">多多网店</router-link>
        <router-link to="/list">抖音小店</router-link>
        <router-link to="/list">其他网店</router-link>
      </dd>
    </dl>

    <dl class="box1">
      <dt>
        <img
          src="@/assets/images/home/menu_ico/qf.png"
          class="imghover"
          alt=""
        />
        <p>企业服务</p>
      </dt>
      <dd>
        <router-link to="/gszh">国内注册</router-link>
        <router-link to="/gszh">海外注册</router-link>
        <router-link to="/gszh">海外开户</router-link>
        <router-link to="/gszh">公司转让</router-link>
        <router-link to="/gszh">公司注销</router-link>
        <router-link to="/gszh">办许可证</router-link>
      </dd>
    </dl>

    <dl class="box1">
      <dt>
        <img
          src="@/assets/images/home/menu_ico/zc.png"
          class="imghover"
          alt=""
        />
        <p>知识产权</p>
      </dt>
      <dd>
        <router-link to="/gszh">商标注册</router-link>
        <router-link to="/gszh">商标授权</router-link>
        <router-link to="/gszh">商标转让</router-link>
        <router-link to="/gszh">版权专利</router-link>
        <router-link to="/gszh">著作申请</router-link>
        <router-link to="/gszh">海外商标</router-link>
        <a style="opacity: 0">海外商标</a>
      </dd>
    </dl>
  </div>
</template>

<style lang="scss" scoped>
/* 下拉菜单栏 */
.menu {
  width: 210px;
  height: 550px;
  background: #fff;

  dl.box1 {
    cursor: pointer;
    padding-left: 3px;
    border-bottom: 1px solid rgba(166, 166, 166, 0.15);
    color: #fffdf8;
    margin-bottom: 5px;
    padding: px 0;
    height: 106px;
    box-sizing: border-box;

    img {
      vertical-align: middle;
      width: 20px;
      height: 20px;
      margin-top: 5px;
    }

    p {
      font-size: 15px;
      color: #333333;
      font-weight: bold;
      margin-bottom: -5px;
    }

    dt {
      display: flex;
      align-items: center;
      padding-left: 10px;

      .imghover {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        filter: brightness(0) saturate(100%) hue-rotate(180deg);
      }
    }

    dd a {
      float: left;
      width: 28%;
      color: #333333;
      font-size: 13px;
      text-decoration: none;
      margin-top: 15px;
      padding: 0px 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    dd a:hover {
      color: #007fff;
    }
  }
}
</style>
