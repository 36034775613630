import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/assets/css/Normalize.css'
import '@/assets/css/common.css'
import '@/assets/fonts/iconfont.css'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'
const pinia = createPinia();
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}


app.use(ElementPlus).use(router).use(pinia).use(ElementPlus).mount('#app')
